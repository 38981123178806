<template>
  <div class="experiences-list">
    <el-row :gutter="4" class="mt-20 mb-40" type="flex" justify="center" >
      <el-col :xs="24" :sm="12">
        <el-select v-model="sortOption" class="experience-filter">
          <el-option v-for="o in sortOptions" :key="o.label" :label="o.label" :value="o.value" />
        </el-select>
      </el-col>
    </el-row>

    <div v-loading="loading">
      <template v-if="experiences.length === 0">
        <div>You have no experiences to moderate.</div>
      </template>

      <template v-else>
        <el-row :gutter="4">
          <el-col :xs="24" :sm="12" v-for="e of filteredExperiences" :key="e.id">
            <ExperienceCard :experience="e" />
          </el-col>
        </el-row>
      </template>
    </div>
  </div>
</template>

<script>
import ExperienceCard from '@/components/experiences/ExperienceCard.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ExperiencesList',

  components: {
    ExperienceCard
  },

  data () {
    return {
      loading: false,
      experiences: [],
      sortOption: 'NAME',
      sortOptions: [
        {
          value: 'NAME',
          label: 'Sort by name'
        },
        {
          value: 'DATE_STARTED',
          label: 'Sort by date started'
        },
        {
          value: 'DATE_ENDED',
          label: 'Sort by date ended'
        }
      ]
    }
  },

  computed: {
    ...mapGetters(['isAuthenticated']),

    filteredExperiences () {
      switch (this.sortOption) {
        case 'DATE_STARTED':
          return [...this.experiences].sort((a, b) => a.start - b.start)

        case 'DATE_ENDED':
          return [...this.experiences].sort((a, b) => a.end - b.end)

        case 'NAME':
        default:
          return [...this.experiences].sort((a, b) => a.name.localeCompare(b.name))
      }
    }
  },

  methods: {
    ...mapActions(['validateToken']),

    async loadExperiences () {
      if (this.isAuthenticated) {
        this.loading = true

        try {
          const response = await this.$ky.get(`${process.env.VUE_APP_API_URL}/experiences`)

          if (!response) {
            this.$notify({
              type: 'error',
              title: 'A network error occured',
              message: 'Please refresh your page.',
              duration: 0
            })
            return
          }

          if (response.status === 200) {
            this.experiences = response.data.data
          }

          if (response.status === 403) {
            this.$notify({
              type: 'error',
              title: 'Not Authorized',
              message: 'You are not authorized to access this page.'
            })
            this.validateToken()
          }
        } finally {
          this.loading = false
        }
      }
    }
  },

  mounted () {
    this.loadExperiences()
  }
}
</script>

<style lang="scss">
.experience-filter {
  width: 100%;

  & > .el-input > .el-input__inner {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 20px;
    color: #fff;
  }
}

</style>

<style lang="scss" scoped>
h2 > span {
  font-weight: 400;
}
</style>
