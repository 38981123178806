<template>
  <div id='experiences'>
    <ExperiencesList />
  </div>
</template>

<script>
import ExperiencesList from '@/components/experiences/ExperiencesList.vue'

export default {
  name: 'Experiences',

  components: {
    ExperiencesList
  }
}
</script>

<style lang="scss" scoped>
</style>
