<template>
  <div class="experiences-card">
    <el-card shadow="never" class="text--center mb-10" :bodyStyle="{ padding: 0 }">
      <router-link :to="{ name: 'ModerateExperience', params: { id: experience.id } }">
        <div class="experience-card-content">
          <h3>{{ name }}</h3>

          <div v-if="!compact" class="experience-type">
            <i class="fa"></i>
            &nbsp;&nbsp;{{ type }}
          </div>

          <div>
            <small class="mt-10 mb-10" style="display: inline-block;">{{ experience.handle }}</small>
          </div>

          <div>
            <i class="fa fa-calendar"></i>
            {{ experience.start | epochAsDate }} - {{ experience.end | epochAsDate }}
          </div>
        </div>
      </router-link>

      <div v-if="!compact" class="experience-card-footer">
        <el-row :gutter="12">
          <el-col :sm="24" :xs="24" :md="12">
            <router-link :to="{ name: 'ModerateExperience', params: { id: experience.id } }">
              <el-button round type="success" style="width: 100%">
                Moderation
              </el-button>
            </router-link>
          </el-col>
          <el-col :xs="24" class="hidden-md-and-up">
            <div style="margin-bottom: 13px;" />
          </el-col>
          <el-col :sm="24" :xs="24" :md="12" class="view-log">
            <router-link :to="{ name: 'AuditExperience', params: { id: experience.id } }">
              <el-button round type="warning" style="width: 100%">
                View log
              </el-button>
            </router-link>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'ExperienceCard',

  props: {
    experience: { type: Object, required: true },
    compact: { type: Boolean, default: false }
  },

  computed: {
    name () {
      if (this.experience && this.experience.name && this.experience.name.length > 0) {
        return this.experience.name.toUpperCase()
      }

      return ''
    },

    type () {
      switch (this.experience.type) {
        case 'MENTION': {
          return 'MENTIONS EXPERIENCE'
        }

        case 'PHOTOMOSAIC':
          return 'PHOTO MOSAIC'
      }

      return this.experience.type || ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colours.scss';

.experiences-card .el-card {
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  color: #fff;

  a {
    text-decoration: none;
    color: inherit;
  }
}

.experience-img {
  border-radius: 30px;
}

.experience-type {
  color: $twitter-blue-logo;
}

.experience-card-content {
  padding: 20px;
}

.experience-card-footer {
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 0.8em;
  padding: 20px;
}

.description {
  color: $gray;
}
</style>
